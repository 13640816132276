@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

.label {
  color: var(--color-cyan-hover);
  display: block;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-m);
  margin: 0 0 8px;

  @media (min-width: design-token("--viewport-l")) {
    margin: 0 0 16px;
    font-size: var(--font-size-xl);
  }

  &.isWhite {
    color: var(--color-yellow);
  }
}

.title {
  color: var(--color-grey-1);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-xxxl);
  line-height: 1;
  margin: 0;
  position: relative;

  @media (min-width: design-token("--viewport-l")) {
    font-size: var(--font-size-xxxxxl);
  }

  &.isWhite {
    color: var(--color-white);
  }
}
